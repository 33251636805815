import React from 'react'
import { css, StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  // obfuscation
  wieojfwejf: {
    display: 'none'
  },
  disableHorozontalResize: {
    resize: 'vertical'
  }
});

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <form name="contact" action="/" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" required />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" required />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea className={css(styles.disableHorozontalResize)} name="message" id="message" rows="6" required ></textarea>
                    </div>
                    <div className="netlify-hidden">
                      <p className={css(styles.wieojfwejf)}>
                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                      </p>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" value="Send Message" className="special" /></li>
                        <li><input type="reset" value="Clear" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:info@eccodesignbuild.com, ">info@eccodesignbuild.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <div>California: 951-496-9511</div>
                        <div>Texas: 512-379-6004</div>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
